(function() {
  'use strict';
  const slidingSections = document.querySelectorAll(
    '.sliding-section'
  );

  slidingSections.forEach(slidingSection => {
    const sectionHeader = slidingSection.querySelector('.section-control');
    const sectionContent = slidingSection.querySelector('.sliding-section-content');

    if (!sectionContent) {
      slidingSection.parentNode.removeChild(slidingSection);
    }

    sectionHeader.addEventListener('click', function(e) {
      const isCollapsed = slidingSection.classList.contains('closed');
      if (isCollapsed) {
        expandContent(sectionContent);
        slidingSection.classList.remove('closed');
        sectionHeader.setAttribute('aria-expanded', 'true');
      } else {
        collapseContent(sectionContent);
        slidingSection.classList.add('closed');
        sectionHeader.setAttribute('aria-expanded', 'false');
      }
    });
  });

  const expandContent = sectionContent => {
    const sectionHeight = sectionContent.scrollHeight;

    sectionContent.style.height = `${sectionHeight}px`;

    // removed as per Clair Smith to Don Fick, Slack 30 Sep 2020
    // corrects a problem where expanded accordion collapses on hover
    // over link text inside accordian.
    // function reset(e) {
    //   sectionContent.removeEventListener('transitionend', reset);
    //   sectionContent.style.height = null;
    // }
    //
    // sectionContent.addEventListener('transitionend', function(e) {
    //   sectionContent.addEventListener('transitionend', reset);
    // });
    // added setTimeout as per Clair Smith. See above.
    // setTimeout(() => {
    //  sectionContent.removeEventListener('transitionend');
    // }, 100);
  };

  const collapseContent = sectionContent => {
    const sectionHeight = sectionContent.scrollHeight;
    const elementTransition = sectionContent.style.transition;
    sectionContent.style.transition = '';

    requestAnimationFrame(function() {
      sectionContent.style.height = `${sectionHeight}px`;
      sectionContent.style.transition = elementTransition;

      requestAnimationFrame(function() {
        sectionContent.style.height = '0px';
      });
    });
  };
})();
